<template>
    <!-- Plan Modaal? -->
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button class="delete" aria-label="close" @click="$parent.close()"></button>
        </header>
        <section class="modal-card-body">
            <form id="plan-cancel-form" @submit.prevent="submit">
                <div class="field">
                    <p class="control">
                        Weet je zeker dat je dit abonnement wilt beëindigen voor {{ organisation.name || 'naamloze organisatie'}}?
                    </p>
                </div>
                <div class="field">
                    <p class="control has-text-danger">
                        Let op! Dit beëindigd alleen het abonnement, <em>niet</em> het account.
                    </p>
                </div>
                <div class="field">
                    <p class="label">Abonnement</p>
                    <p class="control">
                        <v-with :p="getPlan(subscription.plan_id)" v-slot="{p}">
                            {{ p.name }}, 
                        </v-with>
                        lopend van
                        {{ subscription.from|formatTimestamp('YYYY-MM-DD') }}<!-- no space 
                        --><template v-if="subscription.to">
                            tot {{ subscription.to|formatTimestamp('YYYY-MM-DD') }}<!-- no space 
                        --></template>,
                        <template v-if="subscription.subscriptionperiod == 'monthly'">
                            maandelijks
                        </template>
                        <template v-else-if="subscription.subscriptionperiod == 'yearly'">
                            jaarlijks
                        </template>
                        <template v-else>
                            onbekend
                        </template>
                        opzeg- en betaaltermijn.
                    </p>
                </div>
                <b-field
                    label="Abonnement beëindigen per…"
                >
                    <b-radio-button
                        v-model="effect"
                        native-value="immediate"
                    >
                        Direct
                    </b-radio-button>
                    <b-radio-button
                        v-model="effect"
                        native-value="period"
                    >
                        Einde van huidige periode ({{ endOfPeriod|formatTimestamp('YYYY-MM-DD') }})
                    </b-radio-button>
                </b-field>
            </form>
        </section>
        <footer class="modal-card-foot buttons is-right">
            <button class="button" type="button" @click="$parent.close()">
                {{ cancelText }}
            </button>
            <button class="button is-danger" type="submit" form="plan-cancel-form" :class="{'is-loading': isSubmitting}"
                    :disabled="isSubmitting">
                {{ confirmText }}
            </button>
        </footer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getMoment } from '@/utils/functions';
export default {
    props: {
        title: String,
        confirmText: String,
        submitAction: [String, Function],
        destinationField: String,
        cancelText: {
            type: String,
            default: 'Annuleren',
        },
        organisation: Object,
        subscription: Object,
    },
    data() {
        return {
            isSubmitting: false,
            effect: 'immediate',
        }
    },
    computed: {
        ...mapState({
            plans: state => state.plan.all,
        }),
        now() {
            return getMoment();
        },
        endOfPeriod() {
            if (this.subscription.subscriptionperiod === 'monthly') {
                let diff = getMoment().diff(this.subscription.from, 'months', true);
                if (diff <= 0)
                    diff = 1;
                return getMoment(this.subscription.from).add(Math.ceil(diff), 'months');
            } else if (this.subscription.subscriptionperiod === 'yearly') {
                let diff = getMoment().diff(this.subscription.from, 'years', true);
                if (diff <= 0)
                    diff = 1;
                return getMoment(this.subscription.from).add(Math.ceil(diff), 'years');
            }
            // Returning something for good measure…
            return getMoment();
        },
    },
    methods: {
        getPlan(planId) {
            return this.plans.find(p => p.id == planId);
        },
        async submit() {
            this.isSubmitting = true;
            const fields = {
                id: this.subscription._id,
                to_date: (this.effect == 'immediate'? getMoment() : this.endOfPeriod).format('YYYY-MM-DD'),
            };
            let data = {};
            if (this.destinationField)
                data[this.destinationField] = fields;
            else
                data = fields;
            try {
                if (this.submitAction instanceof Function)
                    await this.submitAction(data);
                else
                    await this.$store.dispatch(this.submitAction, data);
                this.$parent.close();
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    let errors = e.response.data;
                    // TODO: can anything go wrong here?
                    alert('Er ging iets mis! Zie console voor meer informatie.');
                    console.error(errors);
                }
            } finally {
                this.isSubmitting = false;
            }            
        },
    },
    created() {
        this.$store.dispatch('plan/ensureData');
    },
}
</script>
