<template>
    <!-- Plan Modaal? -->
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button class="delete" aria-label="close" @click="$parent.close()"></button>
        </header>
        <section class="modal-card-body">
            <div v-for="subscription in organisation.plans" :key="subscription._id" class="media">
                <v-with :plan="getPlan(subscription.plan_id)" v-slot="{plan}">
                    <div class="media-content">
                        <template v-if="plan">
                            <strong>{{ plan.name }}</strong>
                            <template v-if="subscription.invoice_plan_id">
                                <v-with :p="getPlan(subscription.invoice_plan_id)" v-slot="{p}">
                                    (tarieven van {{ p.name }})
                                </v-with>
                            </template>
                            {{ subscription.from|formatTimestamp('YYYY-MM-DD') }}
                            <template v-if="subscription.to">
                                tot {{ subscription.to|formatTimestamp('YYYY-MM-DD') }}
                            </template>
                            <span class="tags is-inline">
                                <span v-if="subscription.pricepermonth != undefined" class="tag mb-0">
                                    {{ subscription.pricepermonth|formatMoney }} p.m.
                                </span>
                                <span v-if="subscription.subscriptionperiod" class="tag mb-0">
                                    <template v-if="subscription.subscriptionperiod == 'monthly'">
                                        maandelijks
                                    </template>
                                    <template v-else-if="subscription.subscriptionperiod == 'yearly'">
                                        jaarlijks
                                    </template>
                                    <template v-else>
                                        onbekende periode
                                    </template>
                                </span>
                                <span v-if="now.isAfter(subscription.from) && (!subscription.to || now.isBefore(subscription.to))" class="tag is-primary mb-0">
                                    huidig
                                </span>
                            </span>
                        </template>
                        <b-skeleton :active="!plan" width="50%" :animated="true"></b-skeleton>
                    </div>
                </v-with>
                <div class="media-right">
                    <b-button
                        v-if="!subscription.to || now.isBefore(subscription.to)"
                        size="is-small"
                        @click="cancelPlan(subscription)"
                    >
                        <span class="icon is-small">
                            <i class="gg-trash" aria-hidden="true"></i>
                            <span class="is-sr-only">Details</span>
                        </span>
                    </b-button>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot buttons is-right">
            <button class="button" type="button" @click="$parent.close()">
                {{ cancelText }}
            </button>
        </footer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getMoment } from '@/utils/functions';
import PlanCancelModal from '@/components/PlanCancelModal';

export default {
    props: {
        title: String,
        confirmText: String,
        cancelText: {
            type: String,
            default: 'Annuleren',
        },
        organisationId: String, // Get org from store, so updates on cancellation are automatically processed.
    },
    computed: {
        ...mapState({
            plans: state => state.plan.all,
            organisation(state) {
                return state.organisation.all.find(o => o.id === this.organisationId);
            },
        }),
        now() {
            return getMoment();
        },
    },
    methods: {
        getPlan(planId) {
            return this.plans.find(p => p.id == planId);
        },
        cancelPlan(subscription) {
            this.$buefy.modal.open({
                parent: this,
                component: PlanCancelModal,
                hasModalCard: true,
                trapFocus: true,
                canCancel: ["escape", "outside"],
                props: {
                    title: 'Abonnement beëindigen',
                    confirmText: 'Abonnement beëindigen',
                    organisation: this.organisation,
                    subscription: subscription,
                    destinationField: 'cancel_plan',
                    submitAction: async (data) => await this.$store.dispatch('organisation/updateOrganisation', {id: this.organisation.id, updates: data}),
                },
            });
        },
    },
    created() {
        this.$store.dispatch('plan/ensureData');
    },
}
</script>
