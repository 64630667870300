<template>
<article class="section">
<div class="container">
    <header class="block">
        <h1 class="title">Organisaties</h1>
    </header>
    <section class="block">
        <div class="tabs is-boxed">
            <ul>
                <li :class="{'is-active': filter.include_inactive === false && filter.can_create_labels === null}">
                    <a @click="filter.include_inactive = false; filter.can_create_labels = null">
                        Actief
                    </a>
                </li>
                <li :class="{'is-active': filter.include_inactive === false && filter.can_create_labels === false}">
                    <a @click="filter.include_inactive = false; filter.can_create_labels = false">
                        Geblokkeerd
                    </a>
                </li>
                <li :class="{'is-active': filter.include_inactive === true}">
                    <a @click="filter.include_inactive = true; filter.can_create_labels = null">
                        Alle
                    </a>
                </li>
            </ul>
        </div>
        <div class="level">
            <div class="level-left">
                
            </div>
            <div class="level-right">
                <div class="field has-addons level-item">
                    <div class="control">
                        <label for="search-field" class="is-sr-only">
                            Zoek organisatie
                        </label>
                        <input
                            id="search-field"
                            class="input"
                            type="search"
                            placeholder="Zoek organisatie"
                            @input="handleFilter($event.target.value)"
                        />
                    </div>
                    <div class="control">
                        <button class="button">
                            <span class="icon">
                                <span class="is-sr-only">Zoeken</span>
                                <i class="gg-search" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-table
            :data="data"
            :striped="true"
            :hoverable="true"
            :loading="view.isLoading"
            :mobile-cards="false"

            paginated
            backend-pagination
            :total="meta.total"
            :per-page="meta.page_size"
            @page-change="handlePageChange"
            :current-page="meta.page"
            backend-sorting
            @sort="handleSort"

            :row-class="(row, index) => !row.is_active && 'has-background-danger-light'"
        >
            <b-table-column field="id" :visible="false" sortable/><!-- Hack for default sort -->
            <b-table-column field="name" label="Naam" cell-class="is-vcentered" v-slot="props">
                {{ props.row.name }}
            </b-table-column>
            <b-table-column field="user" label="Gebruiker" cell-class="is-vcentered" v-slot="props">
                <v-with :user="users.filter(i => i.organisation_id == props.row.id)" v-slot="{user}">
                    <div>
                        <template v-if="user">
                            {{ user.map(u => u.email).join(', ') }}
                        </template>
                        <b-skeleton :animated="true" :active="isLoadingUsers"></b-skeleton>
                    </div>
                </v-with>
            </b-table-column>
            <b-table-column field="contact.name" label="Contact" cell-class="is-vcentered" v-slot="props">
                <template v-if="props.row.contact">
                    {{ props.row.contact.name }}
                </template>
            </b-table-column>
            <b-table-column field="contact.email" label="E-mail" cell-class="is-vcentered" v-slot="props">
                <template v-if="props.row.contact">
                    {{ props.row.contact.email }}
                </template>
            </b-table-column>
            <b-table-column field="contact.phone" label="Telefoonnummer" cell-class="is-vcentered" v-slot="props">
                <template v-if="props.row.contact">
                    {{ props.row.contact.phone }}
                </template>
            </b-table-column>
            <b-table-column field="plan.name" label="Abonnement" cell-class="is-vcentered" v-slot="props">
                <template v-if="props.row.plan">
                    {{ props.row.plan.name }}
                </template>
                <b-tooltip
                    v-if="props.row.payment && props.row.payment.status === 'ready'"
                    label="Organisatie heeft een actief incassocontract"
                    type="is-dark"
                    position="is-right"
                >
                    <b-icon icon="check-o" type="is-success"></b-icon>
                </b-tooltip>
                <b-tooltip
                    v-if="props.row.payment && props.row.payment.status !== 'ready'"
                    label="Fout in incasso-contract"
                    type="is-dark"
                    position="is-right"
                >
                    <b-icon icon="danger" type="is-danger"></b-icon>
                </b-tooltip>
            </b-table-column>
            <b-table-column field="last_label_date" label="Laatste label" cell-class="is-vcentered" v-slot="props">
                <v-with :user="users.find(i => i.organisation_id == props.row.id)" v-slot="{lastLabel}">
                    <div>
                        <template v-if="lastLabel">
                            {{ lastLabel.created_at|formatTimestamp('YYYY-MM-DD') }}
                        </template>
                        <b-skeleton :animated="true" :active="isLoadingLastLabels"></b-skeleton>
                    </div>
                </v-with>
            </b-table-column>
            <b-table-column field="admin.status" label="Status" cell-class="is-vcentered" v-slot="props">
                <template v-if="props.row.admin">
                    <b-tooltip v-if="props.row.admin.status === 'action_required'" :label="props.row.admin.action" type="is-dark" dashed>
                        {{ props.row.admin.status|formatStatus }}
                    </b-tooltip>
                    <template v-else>
                        {{ props.row.admin.status|formatStatus }}
                    </template>
                    <b-tag v-show="!props.row.is_active" type="is-danger">Gedeactiveerd</b-tag>
                    <b-tag v-show="!props.row.can_create_labels" type="is-warning">Geblokkeerd</b-tag>
                </template>
            </b-table-column>
            <b-table-column v-slot="props" cell-class="is-vcentered buttons is-right">
                <b-button
                    tag="router-link"
                    :to="{ name: 'organisation-single', params: {organisationId: props.row.id} }"
                    size="is-small"
                >
                    <span class="icon is-small">
                        <i class="gg-eye" aria-hidden="true"></i>
                        <span class="is-sr-only">Details</span>
                    </span>
                </b-button>
                <b-dropdown position="is-bottom-left" append-to-body>
                    <button class="button is-small" slot="trigger">
                        <span class="icon is-small">
                            <i class="gg-more-vertical" aria-hidden="true"></i>
                            <span class="is-sr-only">Meer opties…</span>
                        </span>
                    </button>

                    <b-dropdown-item
                        v-if="props.row.is_active"
                        aria-role="listitem"
                        @click="deactivateOrganisation(props.row)"
                    >
                        <b-icon icon="trash"></b-icon>
                        Deactiveren
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-else
                        aria-role="listitem"
                        @click="activateOrganisation(props.row)"
                    >
                        Activeren
                    </b-dropdown-item>
                </b-dropdown>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">Geen organisaties gevonden</div>
            </template>
        </b-table>
    </section>
</div>
</article>
</template>

<script>
import { mapState } from 'vuex';
import pagination from '@/mixins/pagination';
import { api } from '@/utils';

export default {
    mixins: [pagination],
    data() {
        return {
            filter: {
                include_inactive: false,
                can_create_labels: null,
            },
            lastLabels: [],
            users: [],
            isLoadingLastLabels: true,
            isLoadingUsers: true,
            viewName: 'organisationList',
            loadViewMethod: 'organisation/loadView',
        };
    },
    computed: {
        ...mapState({
            view(state) { return state.organisation.views[this.viewName]; },
        }),
    },
    methods: {
        async loadLastLabels() {
            this.isLoadingLastLabels = true;
            const ids = [...this.data.map(i => i.id)];
            const result = await api.getLastLabels({ids});
            this.lastLabels = result.data;
            this.isLoadingLastLabels = false;
        },
        async loadUsers() {
            this.isLoadingUsers = true;
            const ids = [...this.data.map(i => i.id)];
            const result = await api.getUsers({ids});
            this.users = result.data;
            this.isLoadingUsers = false;
        },
        afterUpdate() {
            this.loadLastLabels();
            this.loadUsers();
        },
        deactivateOrganisation(organisation) {
            this.$buefy.dialog.confirm({
                title: 'Organisatie deactiveren?',
                message: `Weet je zeker dat je deze organisatie (${organisation.name}) wilt deactiveren? De gebruiker verliest dan toegang tot Innosend.`,
                cancelText: 'Annuleren',
                confirmText: 'Deactiveren',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    await this.$store.dispatch('organisation/updateOrganisation', {
                        id: organisation.id,
                        updates: {is_active: false},
                    });
                    this.$store.dispatch('organisation/reloadViews', {silent: false});
                    this.$buefy.toast.open('Organisatie gedeactiveerd!');
                },
            });
        },
        activateOrganisation(organisation) {
            this.$buefy.dialog.confirm({
                title: 'Organisatie activeren?',
                message: `Weet je zeker dat je deze organisatie (${organisation.name}) wilt activeren? De gebruiker krijgt dan weer Innosend.`,
                cancelText: 'Annuleren',
                confirmText: 'Activeren',
                type: 'is-success',
                hasIcon: true,
                onConfirm: async () => {
                    await this.$store.dispatch('organisation/updateOrganisation', {
                        id: organisation.id,
                        updates: {is_active: true},
                    });
                    this.$store.dispatch('organisation/reloadViews', {silent: false});
                    this.$buefy.toast.open('Organisatie geactiveerd!');
                },
            });
        },
    },
    filters: {
        formatStatus(status) {
            const mapping = {
                'new': 'Nieuw',
                'crm': 'Opgevolgd in CRM',
                'disregard': 'Niet meer opvolgen',
                'action_required': 'Actie vereist',
            };

            const s = (new String(status)).toLowerCase();

            if (mapping[s])
                return mapping[s];
            else if (status)
                return status;
            return 'Onbekend';
        },
    },
    watch: {
        'filter.include_inactive' () {
            this.update(this.meta.page);
        }
    },
};
</script>
