<template>
<article class="section">
<div class="container">
    <header class="block">
        <!-- I'm not too fond of this header, but it works for now... -->
        <nav class="block">
            <b-button @click="$router.backOrDefault(defaultReturnRoute)" icon-left="arrow-left">
                Terug
            </b-button>
        </nav>
        <h1 class="title">
            <template v-if="organisation">{{ organisation.name }}</template>
            <b-skeleton :animated="true" :active="!organisation" width="50%"></b-skeleton>
        </h1>
    </header>
    <section v-if="organisation" class="box">
        <h2 class="title is-4">Status</h2>
        <form id="organisation-form" @submit.prevent="submit">
            <b-field
                    label="Status"
                    :type="{'is-danger': errors.admin.status.length > 0}"
                    :message="errors.admin.status.join(', ')"
            >
                <b-select v-model="fields.admin.status" placeholder="Selecteer een status" expanded required>
                    <option value="new">Nieuw</option>
                    <option value="crm">Opgevolgd in CRM</option>
                    <option value="disregard">Niet meer opvolgen</option>
                    <option value="action_required">Actie vereist</option>
                </b-select>
            </b-field>
            <b-field
                    v-show="fields.admin.status === 'action_required'"
                    label="Actie"
                    :type="{'is-danger': errors.admin.action.length > 0}"
                    :message="errors.admin.action.join(', ')"
            >
                <b-input v-model="fields.admin.action"></b-input>
            </b-field>
            <b-button type="is-primary" native-type="submit" :loading="isSubmitting">
                Opslaan
            </b-button>
        </form>
    </section>
    <section v-if="organisation" class="box">
        <h2 class="title is-4">Account</h2>
        <div class="columns">
            <div class="column properties">
                <h3 class="title is-5">Contact</h3>
                <template v-if="organisation.contact">
                    <div class="property">
                        <div class="name">Bedrijfsnaam</div>
                        <div class="value">
                            {{ organisation.name }}
                        </div>
                    </div>
                    <div class="property">
                        <div class="name">Naam</div>
                        <div class="value">
                            {{ organisation.contact.name }}
                        </div>
                    </div>
                    <div class="property">
                        <div class="name">E-mail</div>
                        <div class="value">
                            {{ organisation.contact.email }}
                        </div>
                    </div>
                    <div class="property">
                        <div class="name">Telefoonnummer</div>
                        <div class="value">
                            {{ organisation.contact.phone }}
                        </div>
                    </div>
                </template>
                <p class="block">
                    <router-link :to="{ name: 'organisation-form', params: {organisationId: organisation.id} }">Contactgegevens wijzigen</router-link>
                </p>
                <h3 class="title is-5">Gebruiker</h3>
                <div class="property">
                    <div class="name">Email</div>
                    <div class="value">
                        <template v-if="users">
                            {{ users.map(u => u.email).join(', ') }}
                        </template>
                        <b-skeleton :animated="true" :active="isLoadingUsers" width="10em"></b-skeleton>
                    </div>
                </div>
                <p class="buttons">
                    <b-button @click="impersonate">Impersonate</b-button>
                </p>
            </div>
            <div class="column properties">
                <h3 class="title is-5">Incasso</h3>
                <template v-if="organisation.payment && organisation.payment.status === 'ready'">
                    <div class="property">
                        <div class="name">Bankrekening (IBAN)</div>
                        <div class="value">
                            {{ organisation.payment.iban }}
                        </div>
                    </div>
                    <div class="property">
                        <div class="name">Kenmerk</div>
                        <div class="value">
                            {{ organisation.payment.mandate_reference }}
                        </div>
                    </div>
                    <div class="property">
                        <div class="name">Incasso aangemaakt op</div>
                        <div class="value">
                            {{ organisation.payment.created_at|formatTimestamp("DD-MM-YYYY") }}
                        </div>
                    </div>
                </template>
                <div v-else-if="organisation.payment && organisation.payment.status === 'incomplete'" class="content">
                    <p>Incasso-overeenkomst niet volledig.</p>
                    <template v-if="organisation.payment.first_payment_url">
                        <p>
                            De eenmalige betaling van € 0,01 is nog niet afgerond. Deze betaling is noodzakelijk voor het ondertekenen van de incasso-overeenkomst.
                        </p>
                    </template>
                </div>
                <div v-else-if="organisation.payment && organisation.payment.status === 'error'" class="content">
                    <p>Fout in de incasso-overeenkomst.</p>
                </div>
                <div v-else class="content">
                    <p>Geen incasso-overeenkomst beschikbaar.</p>
                </div>
                <h3 class="title is-5">Factuuradres</h3>
                <div class="property">
                    <div class="name">E-mail</div>
                    <div v-if="organisation.payment_address" class="value">
                        {{ organisation.payment_address.email }}
                    </div>
                    <div v-else class="value">
                        –
                    </div>
                </div>
                <div class="property">
                    <div class="name">Adres</div>
                    <div v-if="organisation.payment_address" class="value">
                        {{ organisation.payment_address.street }}
                        {{ organisation.payment_address.housenumber }}<br />
                        {{ organisation.payment_address.zipcode }}
                        {{ organisation.payment_address.city }}<br />
                        <country v-model="organisation.payment_address.country" />
                    </div>
                    <div v-else class="value">
                        – 
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section v-if="organisation" class="box">
        <h2 class="title is-4">Abonnement</h2>
        <div v-if="organisation.plan" class="content">
            <p>
                <strong>{{ organisation.plan.name }}</strong>,
                actief sinds {{ organisation.plan.subscription.from|formatTimestamp('YYYY-MM-DD') }}
                <template v-if="organisation.plan.subscription.to">
                    en verloopt {{ organisation.plan.subscription.to|formatTimestamp('YYYY-MM-DD') }}
                </template>
            </p>
            <p class="buttons">
                <b-button @click="addPlan">Abonnement wijzigen</b-button>
                <b-button @click="cancelPlan">Abonnement beëindigen</b-button>
                <b-button @click="showPlanHistory">Geschiedenis</b-button>
            </p>
        </div>
        <div v-else class="content">
            <p>
                Deze organisatie heeft momenteel geen actief abonnement.
            </p>
            <p class="buttons">
                <b-button @click="addPlan">Abonnement instellen</b-button>
                <b-button @click="showPlanHistory">Geschiedenis</b-button>
            </p>
        </div>
        <div v-if="organisation.plan" class="columns">
            <div class="column properties">
                <h3 class="title is-5">Tarieven</h3>
                <div class="property">
                    <div class="name">Opzeg- en betaaltermijn</div>
                    <div v-if="organisation.plan.subscription.subscriptionperiod == 'monthly'" class="value">
                        Maandelijks
                    </div>
                    <div v-else-if="organisation.plan.subscription.subscriptionperiod == 'yearly'" class="value">
                        Jaarlijks
                    </div>
                    <div v-else class="value">Onbekend</div>
                </div>
                <div class="property">
                    <div class="name">Maandbedrag</div>
                    <div v-if="organisation.plan.subscription.pricepermonth != undefined" class="value">
                        <del v-if="organisation.plan.subscription.subscriptionperiod == 'monthly'" class="mr-2">
                            {{ organisation.plan.payment.pricepermonth_monthly|formatMoney }}
                        </del>
                        <del v-else-if="organisation.plan.subscription.subscriptionperiod == 'yearly'" class="mr-2">
                            {{ organisation.plan.payment.pricepermonth_yearly|formatMoney }}
                        </del>
                        <del v-else  class="mr-2">Onbekend</del>
                        {{ organisation.plan.subscription.pricepermonth|formatMoney }}
                    </div>
                    <div v-else-if="organisation.plan.subscription.subscriptionperiod == 'monthly'" class="value">
                        {{ organisation.plan.payment.pricepermonth_monthly|formatMoney }}
                    </div>
                    <div v-else-if="organisation.plan.subscription.subscriptionperiod == 'yearly'" class="value">
                        {{ organisation.plan.payment.pricepermonth_yearly|formatMoney }}
                    </div>
                    <div v-else class="value">Onbekend</div>
                </div>
                <div class="property">
                    <div class="name">Labelprijzen</div>
                    <template v-if="!isLoadingLabelPrices">
                        <div v-if="labelPrices.length > 0" class="value">
                            Eigen tarieven: 
                            <span v-for="price, idx in labelPrices" :key="price.id">
                                <!-- Weird line breaking (before end of tag) to make sure comma's look correct -->
                                <router-link :to="{name: 'price-single', params: {priceId: price.id}}">
                                    <v-with :courier="getCourier(price.courier_id)" v-slot="{courier}">
                                        <template v-if="courier">{{ courier.service_name }}</template><!-- no space 
                                        --><b-skeleton v-else width="15%" :animated="true"></b-skeleton><!-- no space 
                                    --></v-with><!-- no space 
                                --></router-link><!-- no space 
                                -->{{ labelPrices.length - 1 > idx ? ',' : '' }}
                            </span>
                        </div>
                        <div class="value">
                            <template v-if="labelPrices.length > 0">
                                Overige vervoerders:
                            </template>
                            <v-with v-if="organisation.plan.subscription.invoice_plan_id" :p="getPlan(organisation.plan.subscription.invoice_plan_id)" v-slot="{p}">
                                {{ p.name }}
                            </v-with>
                            <template v-else>
                                {{ organisation.plan.name }}
                            </template>
                        </div>
                    </template>
                    <b-skeleton v-else width="50%" :animated="true"></b-skeleton>
                </div>
            </div>
            <div class="column properties">
                <h3 class="title is-5">Limieten</h3>
                <div class="property">
                    <div class="name">Innosend verzendcontracten</div>
                    <div class="value">{{ organisation.plan.quota.couriers_innosend|formatPlanQuota }}</div>
                </div>
                <div class="property">
                    <div class="name">Eigen verzendcontracten</div>
                    <div class="value">{{ organisation.plan.quota.couriers_owncontract|formatPlanQuota }}</div>
                </div>
                <div class="property">
                    <div class="name">Pakketten</div>
                    <div class="value">Tot {{ organisation.plan.quota.labels|formatPlanQuota }} per maand</div>
                </div>
            </div>
        </div>
    </section>
    <section v-if="organisation" class="box block">
        <h2 class="title is-4">Bedrijfsgegevens</h2>
        <div class="columns">
            <div class="column properties">
                <h3 class="title is-5">Afzenderadres</h3>
                <template v-if="organisation.sender_address">
                    <div class="property">
                        <div class="name">Naam</div>
                        <div class="value">
                            {{ organisation.sender_address.name }}
                        </div>
                    </div>
                    <div class="property">
                        <div class="name">Adres</div>
                        <div class="value">
                            <address>
                                {{ organisation.sender_address.street }}
                                {{ organisation.sender_address.housenumber }}<br />
                                {{ organisation.sender_address.zipcode }}
                                {{ organisation.sender_address.city }}<br />
                                <country v-model="organisation.sender_address.country" />
                            </address>
                        </div>
                    </div>
                </template>
            </div>
            <div class="column properties">
                <h3 class="title is-5">Bedrijfsadres</h3>
                <template v-if="organisation.company_address">
                    <div class="property">
                        <div class="name">Adres</div>
                        <div class="value">
                            <address>
                                {{ organisation.company_address.street }}
                                {{ organisation.company_address.housenumber }}<br />
                                {{ organisation.company_address.zipcode }}
                                {{ organisation.company_address.city }}<br />
                                <country v-model="organisation.company_address.country" />
                            </address>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</div>
</article>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/utils';
import { getMoment } from '@/utils/functions';
import PlanModal from '@/components/PlanModal';
import PlanHistoryModal from '@/components/PlanHistoryModal';
import PlanCancelModal from '@/components/PlanCancelModal';
import Country from '@/components/Country';
import {KEYCLOAK_ADMIN_URL, KEYCLOAK_REALM} from '@/constants.js';

export default {
    components: {Country},
    data() {
        let fields = {
            admin: {
                status: null,
                action: null,
            },
        };

        return {
            isSubmitting: false,
            defaultReturnRoute: { name: 'organisation-list' },
            fields,
            labelPrices: [],
            isLoadingLabelPrices: false,
            users: [],
            isLoadingUsers: false,
            errors: {
                admin: {
                    status: [],
                    action: [],
                },
            },
        };
    },
    computed: {
        ...mapState({
            couriers: state => state.courier.all,
            isLoadingCourier: state => state.courier.isLoading,
            plans: state => state.plan.all,
            organisation(state) {
                return state.organisation.all.find(o => o.id === this.$route.params.organisationId);
            },
        }),
        now() {
            return getMoment();
        },
    },
    methods: {
        async loadCouriers() {
            // async so this call can run in the background
            if (this.labelPrices) {
                const ids = [...new Set(this.labelPrices
                    .filter(o => o.courier_id && !this.getCourier(o.courier_id))
                    .map(o => o.courier_id)
                )];
                if (ids.length > 0)
                    await this.$store.dispatch('courier/getCouriers', {ids});
            }
        },
        async loadLabelPrices() {
            if (!this.organisation)
                return;
            this.isLoadingLabelPrices = true;
            const result = await api.getLabelPrices({organisation_id: this.organisation.id});
            this.labelPrices = result;
            this.loadCouriers();
            this.isLoadingLabelPrices = false;
        },
        async loadUsers() {
            this.isLoadingUsers = true;
            const ids = [this.$route.params.organisationId];
            const result = await api.getUsers({ids});
            this.users = result.data;
            this.isLoadingUsers = false;
        },
        getCourier(courierId) {
            return this.couriers.find(o => o.id == courierId);
        },
        getPlan(planId) {
            return this.plans.find(p => p.id == planId);
        },
        updateFields (fields, organisation) {
            if (organisation) {
                if (organisation.admin)
                    Object.assign(fields.admin, organisation.admin);
            }
        },
        validate () {
            let success = true;
            this.errors = {
                admin: {
                    status: [],
                    action: [],
                },
            };

            if (!['new', 'crm', 'disregard', 'action_required'].includes(this.fields.admin.status)) {
                success = success && false;
                this.errors.admin.action.push('Ongeldige status');
            }

            if (this.fields.admin.status === 'action_required' && !this.fields.admin.action.trim()) {
                success = success && false;
                this.errors.admin.action.push('Actie kan niet leeg zijn');
            }
            return success;
        },
        async submit() {
            if (!this.validate())
                return;

            this.isSubmitting = true;
            try {
                await this.$store.dispatch('organisation/updateOrganisation', {id: this.organisation.id, updates: this.fields});
                // Use push for navigation, as action is not semantically equivalent to going back in history
                this.$router.push(this.$router.getPreviousRoute() || this.defaultReturnRoute);
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    let errors = e.response.data;
                    if (errors.admin) {
                        for (const field in errors.admin)
                            this.errors.admin[field] = this.errors.admin[field].concat(errors.admin[field]);
                    }
                }
            } finally {
                this.isSubmitting = false;
            }
        },
        addPlan() {
            this.$buefy.modal.open({
                parent: this,
                component: PlanModal,
                hasModalCard: true,
                trapFocus: true,
                canCancel: ["escape", "outside"],
                props: {
                    title: 'Abonnement wijzigen',
                    confirmText: 'Abonnement wijzigen',
                    destinationField: 'plan',
                    submitAction: async (data) => await this.$store.dispatch('organisation/updateOrganisation', {id: this.organisation.id, updates: data}),
                },
            });
        },
        showPlanHistory() {
            this.$buefy.modal.open({
                parent: this,
                component: PlanHistoryModal,
                hasModalCard: true,
                trapFocus: true,
                canCancel: ["escape", "outside"],
                props: {
                    title: 'Abonnementsgeschiedenis',
                    cancelText: 'Sluiten',
                    organisationId: this.organisation.id,
                },
            });
        },
        cancelPlan() {
            this.$buefy.modal.open({
                parent: this,
                component: PlanCancelModal,
                hasModalCard: true,
                trapFocus: true,
                canCancel: ["escape", "outside"],
                props: {
                    title: 'Abonnement beëindigen',
                    confirmText: 'Abonnement beëindigen',
                    organisation: this.organisation,
                    subscription: this.organisation.plan.subscription,
                    destinationField: 'cancel_plan',
                    submitAction: async (data) => await this.$store.dispatch('organisation/updateOrganisation', {id: this.organisation.id, updates: data}),
                },
            });
        },
        async impersonate() {
            if (!this.users)
                return;

            const response = await fetch(
                `${KEYCLOAK_ADMIN_URL}/realms/${KEYCLOAK_REALM}/users/${this.users[0].keycloak_id}/impersonation`,
                {
                    'method': 'post',
                    'headers':  new Headers({
                        ...this.$store.state.auth.currentAuthHeaders,
                    }),
                    'credentials': 'include',
                },
            );
            const result = await response.json();

            window.open(result.redirect, '_blank');
        },
    },
    async created () {
        this.$store.dispatch('plan/ensureData');
        // Make sure organisation is loaded
        await this.$store.dispatch('organisation/getObject', this.$route.params.organisationId);
        // this.updateFields(this.fields, this.organisation);
    },
    filters: {
        formatPlanQuota(value) {
            if (value === null) {
                return 'Niet toegestaan';
            } else if (value === 0) {
                return 'Geen limiet';
            } else if (value && value > 0){
                return value;
            }
        },
    },
    watch: {
        organisation: {
            handler (organisation) {
                this.updateFields(this.fields, organisation);
                this.loadLabelPrices();
                this.loadUsers();
            },
            immediate: true,
        },
    }
};
</script>
