<template>
    <!-- Plan Modaal? -->
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button class="delete" aria-label="close" @click="$parent.close()"></button>
        </header>
        <section class="modal-card-body">
            <form id="plan-form" @submit.prevent="submit">
                <b-collapse :open="!hasSplitPlan" class="field" aria-id="regularPlanFieldsCollapse" animation="">
                    <b-field label="Abonnement">
                        <b-select v-model="fields.plan_id" placeholder="Selecteer een abonnement" expanded required>
                            <option v-for="plan in plans" :value="plan.id" :key="plan.id">{{ plan.name }}</option>
                        </b-select>
                    </b-field>
                    <p class="field">
                        <a aria-controls="splitPlanFieldsCollapse" @click="hasSplitPlan = true">
                            Gebruik verschillende abonnementen voor limieten en labeltarieven.
                        </a>
                    </p>
                </b-collapse>
                <b-collapse :open="hasSplitPlan" class="field" aria-id="splitPlanFieldsCollapse" animation="">
                    <b-field label="Limieten van">
                        <b-select v-model="fields.plan_id" placeholder="Selecteer een abonnement" expanded required>
                            <option v-for="plan in plans" :value="plan.id" :key="plan.id">{{ plan.name }}</option>
                        </b-select>
                    </b-field>
                    <b-field label="Labeltarieven van">
                        <b-select v-model="fields.invoice_plan_id" placeholder="Selecteer een abonnement" expanded required>
                            <option v-for="plan in plans" :value="plan.id" :key="plan.id">{{ plan.name }}</option>
                        </b-select>
                    </b-field>
                    <p class="field">
                        <a aria-controls="regularPlanFieldsCollapse" @click="hasSplitPlan = false">
                            Gebruik hetzelfde abonnement voor limieten en labeltarieven.
                        </a>
                    </p>
                </b-collapse>
                <div class="field">
                    <div class="columns">
                        <div class="column is-half">
                            <b-field label="Prijs per maand">
                                <p class="control">
                                    <span class="button is-static">€</span>
                                </p>
                                <b-input v-model="fields.pricepermonth" type="number" min="0" step="0.01" expanded :disabled="!hasCustomPricePerMonth"></b-input>
                            </b-field>
                        </div>
                        <div class="column is-half">
                            <b-field label="Prijs per jaar">
                                <p class="control">
                                    <span class="button is-static">€</span>
                                </p>
                                <b-input :value="(fields.pricepermonth * 12)|formatNumber({minimumFractionDigits: 2, maximumFractionDigits: 2})" type="text" step="0.01" expanded disabled></b-input>
                            </b-field>
                        </div>
                    </div>
                </div>
                <p class="field">
                    <a @click="hasCustomPricePerMonth = !hasCustomPricePerMonth">
                        <template v-if="hasCustomPricePerMonth">Gebruik standaard prijs per maand.</template>
                        <template v-else>Prijs per maand aanpassen.</template>
                    </a>
                </p>
                <b-field
                    label="Begindatum"
                    :type="{'is-danger': errors.from_date.length > 0}"
                    :message="errors.from_date.join(', ')"
                >
                    <b-datepicker
                        v-model="fields.from_date"
                        :min-date="today"
                        icon="calendar-today"
                        locale="nl-NL"
                        editable
                        position="is-bottom-left"
                        ref="planFromPicker"
                        :append-to-body="true"
                    >
                        <div class="buttons is-right">
                            <b-button type="is-primary" @click="togglePicker('planFromPicker')">OK</b-button>
                        </div>
                    </b-datepicker>
                </b-field>
                <b-field
                    label="Einddatum (optioneel)"
                    :type="{'is-danger': errors.to_date.length > 0}"
                    :message="errors.to_date.join(', ')"
                >
                    <b-datepicker
                        v-model="fields.to_date"
                        :min-date="fields.from_date"
                        icon="calendar-today"
                        locale="nl-NL"
                        editable
                        position="is-bottom-left"
                        ref="planToPicker"
                        :append-to-body="true"
                        placeholder="Geen einddatum"
                    >
                        <div class="buttons is-right">
                            <b-button type="is-primary" @click="togglePicker('planToPicker')">OK</b-button>
                        </div>
                    </b-datepicker>
                </b-field>
                <b-field
                    label="Opzeg- en betaaltermijn"
                    :type="{'is-danger': errors.subscriptionperiod.length > 0}"
                    :message="errors.subscriptionperiod.join(', ')"
                >
                    <b-radio-button
                        v-model="fields.subscriptionperiod"
                        native-value="monthly"
                    >
                        Maandelijks
                    </b-radio-button>
                    <b-radio-button
                        v-model="fields.subscriptionperiod"
                        native-value="yearly"
                    >
                        Jaarlijks
                    </b-radio-button>
                </b-field>
            </form>
        </section>
        <footer class="modal-card-foot buttons is-right">
            <button class="button" type="button" @click="$parent.close()">
                {{ cancelText }}
            </button>
            <button class="button is-primary" type="submit" form="plan-form" :class="{'is-loading': isSubmitting}"
                    :disabled="isSubmitting">
                {{ confirmText }}
            </button>
        </footer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getMoment } from '@/utils/functions';
export default {
    props: {
        title: String,
        confirmText: String,
        submitAction: [String, Function],
        cancelText: {
            type: String,
            default: 'Annuleren',
        },
        destinationField: String,
        value: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        let fields = {
            plan_id: null,
            invoice_plan_id: null,
            from_date:  new Date(),
            to_date: null,
            subscriptionperiod: 'monthly',
            pricepermonth: null,
        };
        Object.assign(fields, this.value);
        return {
            hasSplitPlan: !!fields.invoice_plan_id, // initialise as true if invoice_plan, else intitialise as false
            hasCustomPricePerMonth: !!fields.pricepermonth,
            testModal: true,
            isSubmitting: false,
            fields,
            errors: {
                plan_id: [],
                invoice_plan_id: [],
                from_date: [],
                to_date: [],
                subscriptionperiod: [],
                pricepermonth: [],
            },
        }
    },
    computed: {
        ...mapState({
            plans: state => state.plan.all,
        }),
        today() {
            return new Date();
        },
    },
    methods: {
        getPlan(planId) {
            return this.plans.find(p => p.id == planId);
        },
        async submit() {
            this.isSubmitting = true;
            const fields = {
                plan_id: this.fields.plan_id,
                from_date: getMoment(this.fields.from_date).format('YYYY-MM-DD'),
                subscriptionperiod: this.fields.subscriptionperiod,
            };
            if (this.fields.invoice_plan_id)
                fields.invoice_plan_id = this.fields.invoice_plan_id;
            if (this.fields.to_date)
                fields.to_date = getMoment(this.fields.to_date).format('YYYY-MM-DD');
            if (this.hasCustomPricePerMonth)
                fields.pricepermonth = this.fields.pricepermonth;

            let data = {};
            if (this.destinationField)
                data[this.destinationField] = fields;
            else
                data = fields;
            try {
                if (this.submitAction instanceof Function)
                    await this.submitAction(data);
                else
                    await this.$store.dispatch(this.submitAction, data);
                this.$parent.close();
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    let errors = e.response.data;

                    if (this.destinationField)
                        errors = e.response.data[this.destinationField];

                    for (const field in errors)
                        this.errors[field] = this.errors[field].concat(errors[field]);
                }
            } finally {
                this.isSubmitting = false;
            }
        },
        togglePicker(ref) {
            this.$refs[ref].toggle();
        },
        setPricePerMonth() {
            if (this.hasCustomPricePerMonth)
                return;
            const plan = this.getPlan(this.fields.plan_id);
            if (this.fields.subscriptionperiod === 'monthly')
                this.fields.pricepermonth = plan.payment.pricepermonth_monthly;
            else if (this.fields.subscriptionperiod === 'yearly')
                this.fields.pricepermonth = plan.payment.pricepermonth_yearly;
        },
    },
    watch: {
        'fields.plan_id'(newVal, oldVal) {
            if (oldVal === this.fields.invoice_plan_id)
                this.fields.invoice_plan_id = newVal;
            this.setPricePerMonth();
        },
        'fields.subscriptionperiod'() {
            this.setPricePerMonth();
        },
        'hasCustomPricePerMonth'() {
            this.setPricePerMonth();
        },
        'hasSplitPlan'(newVal) {
            if (!newVal)
                this.fields.invoice_plan_id = this.fields.plan_id;
        },
    },
    created() {
        this.$store.dispatch('plan/ensureData');
    },
}
</script>
