<template>
    <span>
        {{ model }}
    </span>
</template>

<script>
    import * as countries from 'i18n-iso-countries';

    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

    export default {
        props: {
            value: String,
        },
        data() {
            return {
                model: this.value,
            };
        },
        watch: {
            value(newVal) {
                this.model = countries.getName(newVal, 'nl');
            }
        },
        created() {
            this.model = countries.getName(this.value, 'nl');
        },
    };
</script>
