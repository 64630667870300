<template>
<form-page>
    <header class="container block">
        <!-- I'm not too fond of this header, but it works for now... -->
        <nav class="block">
            <b-button @click="$router.backOrDefault(defaultReturnRoute)" icon-left="arrow-left">
                Terug
            </b-button>
        </nav>
        <h1 class="title">Organisatie aanpassen</h1>
    </header>

    <section class="container block">
        <form id="organisation-form" @submit.prevent="submit">
            <b-field
                    label="Organisatienaam"
                    :type="{'is-danger': errors.name.length > 0}"
                    :message="errors.name.join(', ')"
            >
                <b-input v-model="fields.name" required></b-input>
            </b-field>
            <b-field
                    label="Contactpersoon"
                    :type="{'is-danger': errors.contact.name.length > 0}"
                    :message="errors.contact.name.join(', ')"
            >
                <b-input v-model="fields.contact.name" required></b-input>
            </b-field>
            <b-field
                    label="E-mailadres"
                    :type="{'is-danger': errors.contact.email.length > 0}"
                    :message="errors.contact.email.join(', ')"
            >
                <b-input v-model="fields.contact.email" type="email" required></b-input>
            </b-field>
            <b-field
                    label="Telefoonnummer"
                    :type="{'is-danger': errors.contact.phone.length > 0}"
                    :message="errors.contact.phone.join(', ')"
            >
                <b-input v-model="fields.contact.phone"></b-input>
            </b-field>
            <b-field
                    label="Status"
                    :type="{'is-danger': errors.admin.status.length > 0}"
                    :message="errors.admin.status.join(', ')"
            >
                <b-select v-model="fields.admin.status" placeholder="Selecteer een status" expanded required>
                    <option value="new">Nieuw</option>
                    <option value="crm">Opgevolgd in CRM</option>
                    <option value="disregard">Niet meer opvolgen</option>
                    <option value="action_required">Actie vereist</option>
                </b-select>
            </b-field>
            <b-field
                    v-show="fields.admin.status === 'action_required'"
                    label="Actie"
                    :type="{'is-danger': errors.admin.action.length > 0}"
                    :message="errors.admin.action.join(', ')"
            >
                <b-input v-model="fields.admin.action"></b-input>
            </b-field>
        </form>
    </section>
    <template #footer>
        <div class="container buttons is-right">
            <b-button @click="$router.backOrDefault(defaultReturnRoute)">
                Annuleren
            </b-button>
            <b-button type="is-primary" native-type="submit" form="organisation-form" :loading="isSubmitting">
                Organisatie aanpassen
            </b-button>
        </div>
    </template>
</form-page>
</template>

<script>
import { mapState } from 'vuex';
import FormPage from '@/components/FormPage';

export default {
    components: {FormPage},
    data() {
        let fields = {
            name: null,
            contact: {
                name: null,
                email: null,
                phone: null,
            },
            admin: {
                status: null,
                action: null,
            },
        };

        return {
            isSubmitting: false,
            defaultReturnRoute: { name: 'organisation-list' },
            fields,
            errors: {
                name: [],
                contact: {
                    name: [],
                    email: [],
                    phone: [],
                },
                admin: {
                    status: [],
                    action: [],
                },
            },
        };
    },
    computed: {
        ...mapState({
            organisation(state) {
                return state.organisation.all.find(o => o.id === this.$route.params.organisationId);
            },
        }),
    },
    methods: {
        updateFields (fields, organisation) {
            if (organisation) {
                if (organisation.name)
                    fields.name = organisation.name;

                if (organisation.contact)
                    Object.assign(fields.contact, organisation.contact);

                if (organisation.admin)
                    Object.assign(fields.admin, organisation.admin);
            }
        },
        validate () {
            let success = true;

            this.errors = {
                name: [],
                contact: {
                    name: [],
                    email: [],
                    phone: [],
                },
                admin: {
                    status: [],
                    action: [],
                },
            };

            if (!['new', 'crm', 'disregard', 'action_required'].includes(this.fields.admin.status)) {
                success = success && false;
                this.errors.admin.action.push('Ongeldige status');
            }

            if (this.fields.admin.status === 'action_required' && !this.fields.admin.action.trim()) {
                success = success && false;
                this.errors.admin.action.push('Actie kan niet leeg zijn');
            }

            return success;
        },
        async submit() {
            if (!this.validate())
                return;

            this.isSubmitting = true;
            try {
                await this.$store.dispatch('organisation/updateOrganisation', {id: this.organisation.id, updates: this.fields});
                // Use push for navigation, as action is not semantically equivalent to going back in history
                this.$router.push(this.$router.getPreviousRoute() || this.defaultReturnRoute);
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    let errors = e.response.data;

                    if (errors.name)
                        this.errors.name = this.errors.name.concat(errors.name);

                    if (errors.contact) {
                        for (const field in errors.contact)
                            this.errors.contact[field] = this.errors.contact[field].concat(errors.contact[field]);
                    }
                }
            } finally {
                this.isSubmitting = false;
            }
        },
    },
    async created () {
        // Make sure organisation is loaded
        await this.$store.dispatch('organisation/getObject', this.$route.params.organisationId);
    },
    watch: {
        organisation: {
            handler (organisation) {
                this.updateFields(this.fields, organisation);
            },
            immediate: true,
        },
    }
};
</script>
